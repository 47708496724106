@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    font-family: "Raleway", sans-serif;
  }
  html {
    scroll-behavior: smooth;
  }
  body {
    color: white;
    background: rgb(28, 28, 31);
  }
  h1 {
    line-height: 1;
  }
  h2 {
    line-height: 1;
  }
  h3 {
    line-height: 1;
  }
  p {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  .text-shadow {
    text-shadow: 0 0 4px black;
  }
  span {
    font-family: inherit;
  }
}
